.imgareaselect-border1 {
	background: 1px #000 repeat-y left top;
}
.imgareaselect-border2 {
	background: 2px #000 repeat-x left top;
}
.imgareaselect-border3 {
	background: 3px #000 repeat-y right top;
}
.imgareaselect-border4 {
	background: 4px #000 repeat-x left bottom;
}
.imgareaselect-border1,
.imgareaselect-border2,
.imgareaselect-border3,
.imgareaselect-border4 {
	filter: alpha(opacity=50);
	opacity: 0.5;
}
.imgareaselect-handle {
	background-color: #fff;
	border: solid 1px #000;
	filter: alpha(opacity=50);
	opacity: 0.5;
}
.imgareaselect-outer {
	background-color: #000;
	filter: alpha(opacity=50);
	opacity: 0.5;
}